import React from 'react';
import { Link } from 'react-router-dom';
import './css/footer.css';

const Footer = ({ mode }) => {
  return (
    <footer className="">
      <div className="footer-main">
        <div className="columns ml-5 ml-sm-3 mb-3">
          <div className="column first-col">
            <Link to="/">
              <img
                src={
                  mode
                    ? `${process.env.PUBLIC_URL}/images/archivia-white.png`
                    : `${process.env.PUBLIC_URL}/images/archivia-black.png`
                }
                width="200px"
                height="200px"
                alt="archivia"
                className=""
              />
            </Link>
            <div className="footer-tagline ml-3 mt-0">
              <p className="mute-p">Get free estimation</p>
              <a href="mailto:info@archiviatech.com" id="info-accent">
                support@archiviatech.com
              </a>
            </div>
            <div className="footer-tagline ml-3 mt-0">
              <p className="mute-p">Call</p>
              <a href="tel:+234 708 675 2351" className="footer-info">
                +234 810 522 3428
                <br/>
                +1 409 220 1494
              </a>
            </div>
          </div>
          <div className="column second-col mt-3 mt-sm-1">
            <h3 className="">Quick Links</h3>
            <Link to="/about" className="sec-link mt-3">
              {' '}
              About Us
            </Link>
            <a
              href="https://blog.archiviatech.com/"
              className="sec-link"
              target="_blank" rel="noopener noreferrer"
            >
              {' '}
              Blog
            </a>

            <Link to="/contact" className="sec-link">
              {' '}
              Contact Us
            </Link>
            <Link to="/sitemap" className="sec-link">
              {' '}
              Site Map
            </Link>
            <Link to="/deliverypolicy" className="sec-link">
              {' '}
              Delivery Policy
            </Link>
            <Link to="/refundpolicy" className="sec-link">
              {' '}
              Refund Policy
            </Link>
            <Link to="/privacypolicy" className="sec-link">
              {' '}
              Privacy Policy
            </Link>
            {/* <Link to="/careers" className="sec-link">
              {' '}
              Careers
            </Link> */}
          </div>
          <div className="column third-col mt-2 mt-sm-1">
            <div>
              <Link to="/contact" className="footer-btn">
                Start A Project
              </Link>{' '}
            </div>
            <div className="mt-3">
              <Link to="/portfolio" className="footer-btn1">
                See Our Work
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="gray">
        <div className="columns pb-4">
          <div className="column is-three-fifths">
            <p className="foot-text pt-3 ml-5">
              &copy;2020 All rights reserved | {'  '}
              <span>Archivia Technologies</span>
            </p>
          </div>
          <div className="column pt-1">
            <div className="footer-icons">
              <a href="https://linkedin.com/company/archiviatech">
                <i className="fa fa-linkedin"></i>
              </a>
              <a href="https://instagram.com/archiviatech">
                <i className="fa fa-instagram"></i>
              </a>
              <a href="https://facebook.com/archiviatech">
                <i className="fa fa-facebook"></i>
              </a>
              <a href="https://twitter.com/archiviatech">
                <i className="fa fa-twitter"></i>
              </a>
              <a href="https://blog.archiviatech.com/">
                <i className="fa fa-rss"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
