import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import './css/testimonial.css';

import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

const Testimonial = () => {
  const options = {
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      450: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <section className="">
      <h2 className="section-heading has-text-centered"> Words From Clients</h2>

      <OwlCarousel
        className="owl-theme"
        autoplay
        loop
        responsiveClass
        margin={8}
        responsive={options.responsive}
        autoplayHoverPause={true}
      >
        <div className="item">
          <div className="card carrd  has-text-centered">
            <div className="card-image  img-area">
              <img
                src={`${process.env.PUBLIC_URL}/images/ndtalks.jpg`}
                className="testi-img"
                width="200px"
                height="200px"
                alt="secure"
              />
            </div>

            <div className="card-content">
              <h3 className="card-title mb-3 img-txt">Chukwukere Nnamdi</h3>
              <h4 className="status">CEO ND Talks</h4>
              <p className="card-para">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </p>
              <hr className="testi-hr" />
              <p className="testi-para">
                I can boldly vouch for the credibility of Archivia Technologies's work. Very Professional and I strongly recommend.
              </p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="card carrd  has-text-centered">
            <div className="card-image  img-area">
              <img
                src={`${process.env.PUBLIC_URL}/images/face-1.jpg`}
                className="testi-img"
                width="200px"
                height="200px"
                alt="secure"
              />
            </div>

            <div className="card-content">
              <h3 className="card-title mb-3 img-txt">Okechukwu Samuel </h3>
              <h4 className="status">CTO Electrogold</h4>
              <p className="card-para">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </p>
              <hr className="testi-hr" />
              <p className="testi-para">
              Archivia technologies is the best, very patient and went extra step to make sure that I was satisfied with their service, love their IT skills!
              </p>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="card carrd  has-text-centered">
            <div className="card-image  img-area">
              <img
                src={`${process.env.PUBLIC_URL}/images/face-4.jpg`}
                className="testi-img"
                alt="secure"
              />
            </div>

            <div className="card-content">
              <h3 className="card-title mb-3 img-txt">Gift Yetunde</h3>
              <h4 className="status">MD Royal Bridals</h4>
              <p className="card-para">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </p>
              <hr className="testi-hr" />
              <p className="testi-para">
              Great Company and Great developers. I am so happy with the nice service delivered, solving all my website issues. Thanks!
              </p>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="card carrd  has-text-centered">
            <div className="card-image  img-area">
              <img
                src={`${process.env.PUBLIC_URL}/images/nathan.jpg`}
                className="testi-img"
                alt="secure"
              />
            </div>

            <div className="card-content">
              <h3 className="card-title mb-3 img-txt">El Nathan</h3>
              <h4 className="status">CEO мо́дник apparel</h4>
              <p className="card-para">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </p>
              <hr className="testi-hr" />
              <p className="testi-para">
              Wow!!! Archiviatech is amazing, 
              always make sure that you are happy by providing all your requests. 
              I highly recommend this Tech Company! Thanks a lot
              </p>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="card carrd  has-text-centered">
            <div className="card-image  img-area">
              <img
                src={`${process.env.PUBLIC_URL}/images/face-3.jpg`}
                className="testi-img"
                width="200px"
                height="200px"
                alt="secure"
              />
            </div>

            <div className="card-content">
              <h3 className="card-title mb-3 img-txt"> Loveth</h3>
              <h4 className="status">CEO Memorable Collections</h4>
              <p className="card-para">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </p>
              <hr className="testi-hr" />
              <p className="testi-para">
              If you are looking for High Quality Web services or Programmers. Archivia Technologies are the best, I highly recommend them.
              </p>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
};

export default Testimonial;
