import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/notfound.css';

const NotFound = () => {
  useEffect(() => {
    document.title = `Oops! You've clicked a wrong link`;
  }, []);
  return (
    <section className="notfound-container">
      <div className="nf-content ">
        <h3 className="nf-h3 ">404</h3>
        <h2 className="nf-h2 ">There is no light down here.</h2>

        <p className="nf-para ">
          Just because your path is different doesn't mean you are lost. The
          page you are looking for is not available right now.{' '}
        </p>

        <Link to="/" className="btn">
          Return to Home
        </Link>
      </div>
    </section>
  );
};

export default NotFound;
