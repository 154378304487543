import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

const Navbar = ({ mode, setDarkMode }) => {
  const [open, setOpen] = useState(false);
 
  return (
    <nav
      className="navbar is-fixed-top"
      role="navigation"
      aria-label="dropdown navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item logo" to="/">
            <img
              src={
                mode
                  ? `${process.env.PUBLIC_URL}/images/archivia-white.png`
                  : `${process.env.PUBLIC_URL}/images/archivia-black.png`
              }
              alt="Logo"
            />
          </Link>
          <span className={`navbar-burger burger ${open ? 'is-active' : ''}`} data-target="navbarMenuHeroB" onClick={() => setOpen(!open)}>
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
        <div id="navbarMenuHeroB" className={`navbar-menu ${open ? 'is-active' : ''}`}>
          <div className="navbar-end">
            <NavLink to="/about" className="navbar-item  nav-link menu-hide " onClick={() => setOpen(false)}>
              About
            </NavLink>

            <NavLink to="/services" className="navbar-item  nav-link menu-hide" onClick={() => setOpen(false)}>
              Services
            </NavLink>

            <NavLink to="/portfolio" className="navbar-item nav-link menu-hide" onClick={() => setOpen(false)}>
              Portfolio
            </NavLink>

            <a
              href="https://blog.archiviatech.com/"
              className="navbar-item nav-link menu-hide"
              target="_blank" rel="noopener noreferrer" onClick={() => setOpen(false)}
            >
              Blog
            </a>

            <span className="navbar-item menu-hide" onClick={() => setOpen(false)}>
              <Link to="/contact" className="nav-btn">
                <span className="nav__link contact-us">Contact Us</span>
                <span className="icon">
                  <i className="fa  fa-arrow-right"></i>
                </span>
              </Link>
            </span>
            <span className="navbar-item">
              <span className="toggle">
                <i
                  className={mode ? 'fas fa-sun ' : 'fas fa-moon '}
                  onClick={() => {setDarkMode((prevMode) => !prevMode); setOpen(false)
                  }}
                ></i>
              </span>
            </span>

            
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
