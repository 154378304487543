import React from 'react';

const Hero = () => {
  return (
    <>
      <section className="hero is-large">
        <div className="container mt-6">
          <div className="columns hero-text">
            <div className="column">
              <h4 className="section__title--intro1 mt-6">
                Top Web and App Development Company 
              </h4>
              <h1 className="hero-title">Your Trusted Development Partner</h1>
              <p className="mt-5 hero-para">
                We help you get an extra lift that your start-up needs, get your
                old brand back with new trends and conventions, and help direct
                your path into the future with a helping hand. We make our
                clients understand our technologies and processes.
              </p>

              <a href="/services" className="hero-btn btnn mt-6">
                What we do
              </a>
            </div>
            <div className="column mt-4">
              <img
                src="/images/connect.svg"
                alt="connect"
                className="hero-img"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
