import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/deliverypolicy.css';

const Privacypolicy = () => {
  useEffect(() => {
    document.title = `Privacy Policy`;
  }, []);
  return (
    
      <section>
        <div className="about-container has-text-centered">
          <div className=" mb-2 mt-6">
            <p className="mb-3">
            We value the privacy and security of the information that you share with us. This Privacy Policy describes how we collect, use, share, protect and process your information. Any information that you provide to us is used strictly to process and fulfill your purchase or for internal analytical purposes. we will not sell or redistribute any of your information to any third party. We use commercially reasonable efforts to protect your information from unauthorized disclosure. We may use the information from your order to process your purchase, provide a refund, provide customer service, improve or customize our products and services or fulfill applicable financial, tax, legal or compliance requirements. If you have any questions or concerns related to this Privacy Policy, please contact us.
            </p>
            
          </div>
        </div>
      </section>
  );
};

export default Privacypolicy;
