import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import 'bulma/css/bulma.css';
import './App.css';

import Navbar from './components/Navbar';
import Footer from './components/footer';
import Main from './components/main';
import About from './pages/about';
import Deliverypolicy from './pages/deliverypolicy';
import Refundpolicy from './pages/refundpolicy';
import Privacypolicy from './pages/privacypolicy';
import Services from './pages/services';
import Enquiry from './pages/enquiry';
import Portfolio from './components/portfolio';

import NotFound from './components/notFound';
import ScrollToTop from './components/scrollUp';

//TODO: Lazy load

function App() {
  const [darkMode, setDarkMode] = useState(getInitialMode());

  useEffect(() => {
    localStorage.setItem('dark', JSON.stringify(darkMode));
  }, [darkMode]);

  function getInitialMode() {
    const isReturningUser = 'dark' in localStorage;
    const savedMode = JSON.parse(localStorage.getItem('dark'));
    const userPrefersDark = getPrefColorScheme();

    if (isReturningUser) {
      return savedMode;
    } else if (userPrefersDark) {
      return true;
    } else {
      return false;
    }
  }

  function getPrefColorScheme() {
    if (!window.matchMedia) return;

    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  return (
    <div className={darkMode ? 'dark-mode' : 'light-mode'}>
      
      <Navbar mode={darkMode} setDarkMode={setDarkMode} />
      <Switch>
        <Route exact path="/">
          <Main />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/deliverypolicy">
          <Deliverypolicy />
          </Route>
          <Route path="/refundpolicy">
          <Refundpolicy />
          </Route>
          <Route path="/privacypolicy">
          <Privacypolicy />
          </Route>
        <Route path="/services">
          <Services />
        </Route>
        <Route path="/portfolio">
          <Portfolio />
        </Route>
        <Route path="/contact">
          <Enquiry />
        </Route>
        
        <Route>
          <NotFound />
        </Route>
      </Switch>

      <Footer mode={darkMode} />
      <ScrollToTop />
    </div>
  );
}

export default App;
