import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/deliverypolicy.css';

const Refundpolicy = () => {
  useEffect(() => {
    document.title = `Refund Policy`;
  }, []);
  return (
    
      <section>
        <div className="about-container has-text-centered">
          <div className=" mb-2 mt-6">
            <p className="mb-3">
            If you are not 100% satisfied with your purchase, at the end of the agreed date of delivery. please contact us and we will provide full refund.
            </p>
            
          </div>
        </div>
      </section>
  );
};

export default Refundpolicy;
