import React from 'react';

import Hero from './hero';
import Services from './Services';
import Tech from './technology';
import Affirmation from './affirmation';
import Testimonial from './testimonial';
import PortPreview from './portPreview';

const Main = () => {
  return (
    <>
      <Hero />
      <Services />
      <Tech />
      <PortPreview/>
      <Affirmation />
      <Testimonial />
    </>
  );
};

export default Main;
