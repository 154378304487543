import React from 'react';
import './css/portfolio.css';


const PortPreview = () => {
    return ( 
        <section>
      <div className="container">
      <h2 className="section-heading has-text-centered">
        Our Work
      </h2>

       <ul className="portfolio-list">
       <li>
           
           <a href="https://www.vertuliedesigns.com/"
              
              target="_blank" rel="noopener noreferrer"><img src="/images/second.jpg" alt="vertulie"/> </a>
             <p className="project-category">ecommerce</p>
             <a href="https://www.vertuliedesigns.com" target="_blank" rel="noopener noreferrer" className="port-title">Vertulie Designs</a>
             <div className="project-desc">
              This is an online store that sells quality necklaces for both male and female.
             </div>
             <div className="project-technology">
               
               <span>Wordpress</span>
               <span>PHP</span>
             </div>
             
           </li>
           <li>
            
           <a href="https://www.beautietimes.com" target="_blank" rel="noopener noreferrer"><img src="/images/beautie-times.jpg" alt="beautietimes"/> </a>
             <p className="project-category">Blog</p>
             <a href="www.beautietimes.com" target="_blank" className="port-title">Beautie Times</a>
             <div className="project-desc">
              A beauty and fashion blog, easy to navigate and well optimized
             </div>
             <div className="project-technology">
               
               <span>wordpress</span>
               <span>PHP</span>
             </div>
           </li>
         <li>
           
           <a href="https://www.devmary.online" target="_blank" rel="noopener noreferrer"><img src="/images/brenda-portfolio.jpg" alt="portfolio"/> </a>
           <p className="project-category"> Portfolio</p>
           <a href="https://www.devmary.online" target="_blank" rel="noopener noreferrer" className="port-title">Dev Mary</a>
           <div className="project-desc">
            It is a portfolio website that displays the skill set and projects done by Mary.
           </div>
           <div className="project-technology">
             <span>React JS</span>
             <span>CSS Grid</span>
           </div>
         </li>
         <li>
           
           <a href="https://www.memorablegiftstore.com" target="_blank" rel="noopener noreferrer"><img src="/images/giftstore.jpg" alt="giftstore"/> </a>
           <p className="project-category">Online Store</p>
           <a href="https://www.memorablegiftstore.com" target="_blank" rel="noopener noreferrer" className="port-title">Memorable Gift Store</a>
           <div className="project-desc">
            A well optimized e-commerce store, where you can get adorable gift items for everyone. 
           </div>
           <div className="project-technology">
             <span>Woocommerce</span>
             <span>Wordpress</span>
             <span>PHP</span>
           </div>
         </li>
         <li>
           
           <a href="https://www.international-womensministry.org" target="_blank" rel="noopener noreferrer"><img src="/images/ministry.jpg" alt="ministry"/> </a>
           <p className="project-category">Religion</p>
           <a href="https://www.international-womensministry.org" target="_blank" rel="noopener noreferrer" className="port-title">International Women Ministry</a>
           <div className="project-desc">
            It is a church website where you can listen to sermons, request prayers and even pay your tithes.
           </div>
           <div className="project-technology">
             <span>CMS</span>
             <span>Wordpress</span>
             <span>PHP</span>
           </div>
         </li>
         <li>
           
           <a href="https://www.aspirationlife.com" target="_blank" rel="noopener noreferrer"><img src="/images/edge.jpg" alt="aspiration"/> </a>
           <p className="project-category">Multi Vendor Ecommerce Website</p>
           <a href="https://www.aspirationlife.com" target="_blank" rel="noopener noreferrer" className="port-title">Aspiration Life</a>
           <div className="project-desc">
            A multi vendor website where multiple retailer sell their goods
           </div>
           <div className="project-technology">
             <span>Woocommerce</span>
             <span>Wordpress</span>
             <span>PHP</span>
           </div>
         </li>
       </ul>
       <a href="/portfolio" className="preview-btn">
                View More <i className="fa  fa-caret-down"></i>
        </a>
      </div>

      
      
    </section>
     );
}
 
export default PortPreview;