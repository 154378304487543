import React, { useEffect } from 'react';
import $ from 'jquery';
import './css/about.css';

const About = () => {
  useEffect(() => {
    document.title = 'About Us - Your Passion, Our Mission';
  }, []);

  let nCount = function (selector) {
    $(selector).each(function () {
      $(this).animate(
        {
          Counter: $(this).text(),
        },
        {
          duration: 4000,
          easing: 'swing',
          step: function (value) {
            $(this).text(Math.ceil(value));
          },
        }
      );
    });
  };

  let a = 0;
  $(window).scroll(function () {
    let oTop = $('.numbers').offset().top - window.innerHeight;
    if (a === 0 && $(window).scrollTop() >= oTop) {
      a++;
      nCount('.rect > h1 > span');
    }
  });
  return (
    <div>
      <section>
        <div className="about-container has-text-centered">
          <div className=" mb-2 mt-6">
            <p className="mb-3">
              Top-notch Web and Mobile Application Development Company
            </p>
            <h1 className="about-title mb-3">
              Our Pride comprises of our People, Vision and Values
            </h1>
            <p>
              Archivia Technologies is not only a well-known IT company, It is a
              family that has talented experts that can help your global brands,
              startups, enterprises with innovative solutions.
            </p>
          </div>
        </div>
      </section>

      <section className="graay">
        <div className="container">
          <figure className="image">
            <img
              src={`${process.env.PUBLIC_URL}/images/team-work.jpg`}
              className="about-img"
              alt="teamwork"
            />
          </figure>
        </div>
      </section>
      <section>
        <div className="columns">
          <div className="column about-col">
            <h3 className="about-h3 mb-3">Who We Are</h3>
            <p>
              Archivia Technologies is a creative web solutions provider company
              based in Lagos, Nigeria. We provide all relevant services under
              one roof such as software development, website and mobile app
              development (Android and iOS), digital marketing, logo and
              graphics design services.
            </p>

            <p className="mt-3">
              We have team of experts for wide range of web services that we
              offer to our clients. We all collectively work towards the
              objective of delivering professional, goal oriented,
              well-engineered web development, designing and digital marketing
              solutions.
            </p>
          </div>
          <div className="column about-col">
            <h3 className="about-h3 mb-3">What We Do</h3>
            <p>
              We aim to take your business to the next level through our best
              web solutions, strategic plans and execution of targeted Internet
              strategies. We design, build and launch new web presence for you
              and partner with you in all online business services.
            </p>
          </div>
        </div>
      </section>
      <section className="section-2 container-fluid p-0 graay">
        <div className="cover">
          <div className="content text-center">
            <h1>Some Features That Makes Us Unique</h1>
            <p>We have helped so many businesses achieve their goals</p>
          </div>
        </div>

        <div className="container is-fluid has-text-center">
          <div className="numbers is-flex">
            <div className="rect">
              <h1>
                <span>352</span> +
              </h1>
              <p>Happy Clients</p>
            </div>
            <div className="rect">
              <h1>
                <span>413</span> +
              </h1>
              <p>Projects Delivered</p>
            </div>
            <div className="rect">
              <h1>
                <span>50</span> +
              </h1>
              <p>Tech Experts</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="columns ">
            <div className="column about-col">
              <h3 className="about-h3 mb-3">Our Passion</h3>
              <p>
                We create websites, custom applications, software and mobile
                apps. Our creative minds use modern technologies and
                collaborative techniques to keep clients constantly satisfied.
              </p>
              <a href="/portfolio" className="about-btn">
                Our Work
              </a>
            </div>
            <div className="col-7 about-col">
              <figure className="image">
                <img
                  src={`${process.env.PUBLIC_URL}/images/happy-team.jpg`}
                  className=""
                  alt="teamwork"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
