import React, { useState } from 'react';
import './css/enquiry.css';
import './css/about.css';

const Enquiry = () => {
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [fullName, setFullName] = useState('');
  const [formAction, setFormAction] = useState('');
  const [description, setDescription] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [descError, setDescError] = useState('');

  const validate = async () => {
    const strongRegex = new RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    if (!strongRegex.test(email)) {
      setEmailError('invalid email address');
      return false;
    } else {
      setEmailError('');
    }

    if (fullName === '') {
      setNameError('Name cannot be blank');
      return false;
    } else {
      setNameError('');
    }

    if (description === '') {
      setDescError('Project Description cannot be blank');
      return false;
    } else {
      setDescError('');
    }

    if (phoneNo === '') {
      setPhoneError('Please Enter a Valid Phone Number');
      return false;
    } else {
      setPhoneError('');
    }

    return true;
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const isValid = validate();

    if (isValid) {
      setFormAction('https://usebasin.com/f/ffbd9d224934');
    }
  };

  return (
    <section>
      <div className="mb-4">
        <div className="columns">
          <div className="column is-one-third">
            <div className="contact-1">
              <h3>Get in touch</h3>
              <div className="con-tact">
                <h4>Phone Numbers</h4>
                <p>
                  <i className="fas fa-phone-square-alt mr-2"></i>
                  <a href="tel:+2347086752351" className="enquiry-link">
                    General Enquiries - +234 708 675 2351
                    <br></br>
                    <i className="fas fa-phone-square-alt mr-2"></i> 
                    International - +1 409 220 1494
                  </a>
                </p>
              </div>
              <div className="con-tact">
                <h4>Address</h4>
                <p>
                  {/* <i className="fas fa-address-card mr-2"></i>
                  <a href="tel:+234 708 675 2351" className="enquiry-link">
                    165 Ehi Road Aba Abia State, Nigeria
                    <br/>

                  </a> */}
                  <i className="fas fa-address-card mr-2"></i>
                  <a href="tel:+234 708 675 2351" className="enquiry-link">
                    16192 Coastal Hwy Lewes,Sussex 19958 Delaware

                  </a>
                </p>
              </div>
              <div className="con-tact">
                <h4>Email Addresses</h4>
                <p>
                  {' '}
                  <i className="fas fa-info-circle mr-2"></i>
                  <a href="mailto:info@archiviatech.com" className="enquiry-link">
                    info@archiviatech.com
                  </a>
                </p>
                <p>
                  <i className="fas fa-headset mr-2"></i>
                  <a href="mailto:support@archiviatech.com" className="enquiry-link">
                    support@archiviatech.com
                  </a>{' '}
                </p>
              </div>
              <div className="con-tact">
                <h4>Business Hours</h4>
                <p> Monday — Friday 9am - 5pm</p>
                <p>Saturday — 10am - 4pm</p>
                <p>Sunday — Closed</p>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="contain">
              <h3>Send us a message</h3>
              <form
                action={formAction}
                method="POST"
                className="project-form"
                onSubmit={handleSubmit}
                id="form"
              >
                <div className="field">
                  <label className="label">Full Name</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Your Name"
                      id="name"
                      onChange={(evt) => setFullName(evt.target.value)}
                    />
                    <i className="fas fa-check-circle"></i>
                    <i className="fas fa-exclamation-circle"></i>

                    <small>{nameError}</small>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Email</label>
                  <div className="control ">
                    <input
                      className="input"
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Your Email"
                      onChange={(evt) => setEmail(evt.target.value)}
                    />
                    <i className="fas fa-check-circle"></i>
                    <i className="fas fa-exclamation-circle"></i>

                    <small>{emailError}</small>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Phone Number</label>
                  <div className="control">
                    <input
                      className="input"
                      type="tel"
                      placeholder="0803 437 1234"
                      id="tel"
                      onChange={(evt) => setPhoneNo(evt.target.value)}
                    />

                    <i className="fas fa-check-circle"></i>
                    <i className="fas fa-exclamation-circle"></i>

                    <small>{phoneError}</small>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Choose A Service</label>
                  <div className="control">
                    <div className="select">
                      <select>
                        <option>Select</option>
                        <option>Web Development</option>
                        <option>Mobile App Development</option>
                        <option>Software Development</option>
                        <option>Digital Marketing</option>
                        <option>UI/UX Design</option>
                        <option>Logo and Graphic Design</option>
                        <option>Business Branding</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Project Description</label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      placeholder="Brief About the Project"
                      onChange={(evt) => setDescription(evt.target.value)}
                    ></textarea>
                    <i className="fas fa-check-circle"></i>
                    <i className="fas fa-exclamation-circle"></i>

                    <small>{descError}</small>
                  </div>
                </div>

                <div className="field is-grouped">
                  <div className="control">
                    <button className="about-btn ">Get a Quote</button>
                  </div>
                  {/* <div className="control">
                  <button className="button is-link is-light">Cancel</button>
                </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Enquiry;
