import React from 'react';
import './css/services.css';
import './css/about.css';
import Enquiry from './enquiry';

const Services = () => {
  return (
    <div>
      <section>
        <div className="about-container has-text-centered">
          <div className=" mb-2 mt-6">
            <h1 className="services-title mb-3">
              Web and Mobile Application Development Services
            </h1>
            <p>
              A successful software application development company, providing a
              full range of website and mobile app development, IoT and other IT
              services and solutions globally.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="columns">
            <div className="column about-col">
              <h3 className="about-h3 mb-3">Web Development</h3>
              <p>
                We create any kind of web application you want using modern
                technologies. Our application are fast, mobile and user
                friendly. We also Develop custom software that suits your needs.
                Archivia does it all by covering a wide spectrum of web
                solutions and frameworks.
              </p>
              <ul>
                <li>Full Stack Development</li>
                <li>Custom Website Development</li>
                <li>Ecommerce store Development</li>
                <li>Progressive Web App Development</li>
                <li>Content Management System</li>
                <li>Blogs</li>
                <li>Landing page</li>
              </ul>
            </div>
            <div className="column about-col">
              <figure className="image">
                <img
                  src={`${process.env.PUBLIC_URL}/images/webdev.jpg`}
                  className=""
                  alt="teamwork"
                />
              </figure>
            </div>
          </div>
          {/* end of column */}
          <div className="columns reverse-columns">
            <div className="column about-col">
              <figure className="image">
                <img
                  src={`${process.env.PUBLIC_URL}/images/mobileapp.jpg`}
                  className=""
                  alt="teamwork"
                />
              </figure>
            </div>
            <div className="column about-col">
              <h3 className="about-h3 mb-3">Mobile App Development</h3>
              <p>
                Archivia is a top mobile app development company that is known
                for delivering iOS, Android, hybrid and cross-platform app
                development services to build next-gen mobile applications using
                the latest technology stack. Our Applications are easy to use,
                fast and efficient.
              </p>
              <ul>
                <li>React Native Development</li>
                <li>Andriod App Development</li>
                <li>iOS App Development</li>
                <li>Flutter App Development</li>
              </ul>
            </div>
          </div>
          {/* end of column */}
          <div className="columns">
            <div className="column about-col">
              <h3 className="about-h3 mb-3">Business Branding</h3>
              <p>
                Branding is a way to identity your business. Your brand has to
                be distinct and memorable, or you'll get lost in the sea of
                companies out there. We can help you business come alive,
                online. Or rebrand your business to convert more customers.
              </p>
              <ul>
                <li>Brand Strategy</li>
                <li>Brand Identity</li>
                <li>Brand Marketing</li>
                <li>Business Growth</li>
              </ul>
            </div>
            <div className="column about-col">
              <figure className="image">
                <img
                  src={`${process.env.PUBLIC_URL}/images/branding.jpg`}
                  className=""
                  alt="teamwork"
                />
              </figure>
            </div>
          </div>
          {/* end of column */}
          <div className="columns reverse-columns">
            <div className="column about-col">
              <figure className="image">
                <img
                  src={`${process.env.PUBLIC_URL}/images/uiux.jpg`}
                  className=""
                  alt="teamwork"
                />
              </figure>
            </div>
            <div className="column about-col">
              <h3 className="about-h3 mb-3">UI/UX Design</h3>
              <p>
                UI/UX design plays an important role to the success of any web
                or mobile app. Our UI/import Enquiry from './enquiry'; UX
                experts can help you turn that great ideas into meaningful
                interactions.
              </p>
              <ul>
                <li>Responsive Web Design</li>
                <li>Mobile App Design</li>
                <li>UI Design</li>
                <li>UX Analysis</li>
              </ul>
            </div>
          </div>
          {/* end of column */}
          <div className="columns">
            <div className="column about-col">
              <h3 className="about-h3 mb-3">Digital Marketing</h3>
              <p>
                Digital marketing is the way to connect with and influence your
                potential customers, online. We will use numerous digital
                tactics and channels to connect your brand to your customers
                where they spend much of their time: online
              </p>
              <ul>
                <li>Search Engine Optimization (SEO)</li>
                <li>Email Marketing</li>
                <li>Social Media Marketing</li>
                <li>Paid advertising</li>
                <li>Content Marketing</li>
              </ul>
            </div>
            <div className="column about-col">
              <figure className="image">
                <img
                  src={`${process.env.PUBLIC_URL}/images/digitalmarket.jpg`}
                  className=""
                  alt="teamwork"
                />
              </figure>
            </div>
          </div>
          {/* end of column */}
          <div className="columns reverse-columns">
            <div className="column about-col">
              <figure className="image">
                <img
                  src={`${process.env.PUBLIC_URL}/images/graphic.jpg`}
                  className=""
                  alt="teamwork"
                />
              </figure>
            </div>
            <div className="column about-col">
              <h3 className="about-h3 mb-3">Logo and Graphic Design</h3>
              <p>
                A good logo is the cornerstone of your brand. It helps customers
                understand who you are, what you do and what you value. We will
                help you create the perfect visual brand mark for a company.
              </p>
              <ul>
                <li>Logo Design</li>
                <li>Graphic Design</li>
                <li>Product Design</li>
                <li>Motion Design</li>
                <li>Animations</li>
              </ul>
            </div>
          </div>
          {/* end of column */}
        </div>
      </section>
      <hr />

      <section className="services-section">
        <div className="has-text-centered mb-5">
          <h3 className="mb-3 project-title">Ready to start a Project?</h3>
          <p>Use this form to tell us about you and your project</p>
        </div>
        <Enquiry />
      </section>
    </div>
  );
};

export default Services;
