import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import './css/services.css';


import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

const Services = () => {
  const options = {
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      450: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <section className="our-services">
      <h2 className="section-heading has-text-centered"> Our Core Services</h2>

      <OwlCarousel
        className="owl-theme"
        autoplay
        loop
        responsiveClass
        margin={8}
        responsive={options.responsive}
        autoplayHoverPause={true}
      >
        <div className="item">
          <div className=" card item-1 has-text-centered">
            <div className="card-image">
              <img
                src={`${process.env.PUBLIC_URL}/images/svg/startup.svg`}
                className="service-img"
                alt="secure"
              />
            </div>

            <div className="card-content">
              <h3 className="card-title">Online Branding for Startups</h3>
              <p className="card-para">
                Are you clueless on how to start your online journey? We are
                here to help, we will get you up and running with all you need
                to excel in the growth of your business online.
              </p>
              <Link to="/services" className="card-link">
                Read More
              </Link>
            </div>
          </div>
        </div>
        <div className="item">
          <div className=" card item-2 has-text-centered">
            <div className="card-image">
              <img
                src={`${process.env.PUBLIC_URL}/images/svg/web-development.svg`}
                className="service-img"
                alt="secure"
              />
            </div>

            <div className="card-content">
              <h3 className="card-title">Web Development</h3>
              <p className="card-para">
                We create custom website and web application solutions. We
                deliver clean, mobile friendly, performance and search engines
                optimized modern website.
              </p>
              <Link to="/services" className=" card-link">
                Read More
              </Link>
            </div>
          </div>
        </div>

        <div className="item">
          <div className=" card item-3 has-text-centered">
            <div className="card-image">
              <img
                src={`${process.env.PUBLIC_URL}/images/svg/mobile-apps.svg`}
                className="service-img"
                alt="secure"
              />
            </div>

            <div className="card-content">
              <h3 className="card-title">Mobile App Development</h3>
              <p className="card-para">
                We build intuitive and engaging Android, iOS and cross-platform
                apps for businesses and enterprises that users love and adapt to
                very quickly.
              </p>
              <Link to="/services" className="card-link">
                Read More
              </Link>
            </div>
          </div>
        </div>

        <div className="item">
          <div className=" card item-4 has-text-centered">
            <div className="card-image">
              <img
                src={`${process.env.PUBLIC_URL}/images/svg/ui-ux.svg`}
                className="service-img"
                alt="secure"
              />
            </div>

            <div className="card-content">
              <h3 className="card-title">UI/UX Design</h3>
              <p className="card-para">
                Starting from idea, visual identity and UI/UX design, our team
                delivers top-notch experiences for maximum user engagement.
              </p>
              <Link to="/services" className="card-link">
                Read More
              </Link>
            </div>
          </div>
        </div>

        <div className="item">
          <div className=" card item-5 has-text-centered">
            <div className="card-image">
              <img
                src={`${process.env.PUBLIC_URL}/images/svg/digital-marketing.png`}
                className="service-img"
                alt="secure"
              />
            </div>

            <div className="card-content">
              <h3 className="card-title">Digital Marketing</h3>
              <p className="card-para">
                We help your company reach a large number of audience by various
                brand awareness scheme, also get higher engagements on social
                media platforms, high conversion rate, real-time and measurable
                results.
              </p>
              <Link to="/services" className="card-link">
                Read More
              </Link>
            </div>
          </div>
        </div>
        <div className="item">
          <div className=" card item-6 has-text-centered">
            <div className="card-image">
              <img
                src={`${process.env.PUBLIC_URL}/images/svg/logo-design.svg`}
                className="service-img"
                alt="secure"
              />
            </div>

            <div className="card-content">
              <h3 className="card-title">Logo Design</h3>
              <p className="card-para">
                We provide logo design from scratch and will provide it with all
                your company standard formats and sizes. We use leading
                designing tools to keep pace with the ever changing world of
                digital design.
              </p>
              <Link to="/services" className="card-link">
                Read More
              </Link>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
};

export default Services;
