import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/deliverypolicy.css';

const Deliverypolicy = () => {
  useEffect(() => {
    document.title = `Delivery POlicy`;
  }, []);
  return (
    
      <section>
        <div className="about-container has-text-centered">
          <div className=" mb-2 mt-6">
            <p className="mb-3">
            Upon receipt of your order, we will perform the requested service in accordance to the terms applicable to the services that you purchased. The nature of the services you purchased, and the agreed date of delivery plays its role on when it will be delivered. The services will be deemed to be successfully delivered to you upon confirmation of satisfication.
            </p>
            
          </div>
        </div>
      </section>
  );
};

export default Deliverypolicy;
