import React from 'react';
import './css/technology.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Tech = () => {
  return (
    <section className="" id="technology">
      <h2 className="section-heading has-text-centered">
        Technologies we work with
      </h2>
      <div className="container">
        <Tabs>
          <TabList>
            <Tab>Frontend</Tab>
            <Tab>Mobile</Tab>
            <Tab>Backend</Tab>
            <Tab>CMS</Tab>
            <Tab>Database</Tab>
            <Tab>Devops</Tab>
          </TabList>

          <TabPanel>
            <div className="content">
              <span className="main-content">
                <i className="fab fa-react fa-3x"></i>

                <h5 className="content-heading">React</h5>
              </span>
              <span className="main-content">
                <i className="fab fa-angular fa-3x"></i>

                <h5 className="content-heading">Angular</h5>
              </span>
              <span className="main-content">
                <i className="fab fa-vuejs fa-3x"></i>

                <h5 className="content-heading">Vue JS</h5>
              </span>
              <span className="main-content">
                <i className="fab fa-html5 fa-3x"></i>

                <h5 className="content-heading">HTML 5</h5>
              </span>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="content">
              <span className="main-content">
                <i className="fab fa-android fa-3x"></i>

                <h5 className="content-heading">Android</h5>
              </span>
              <span className="main-content">
                <i className="fab fa-apple fa-3x"></i>

                <h5 className="content-heading">iOS</h5>
              </span>

              <span className="main-content">
                <i className="fab fa-react fa-3x"></i>

                <h5 className="content-heading">React Native</h5>
              </span>
              <span className="main-content">
                <i className="fab fa-swift fa-3x"></i>

                <h5 className="content-heading">Swift</h5>
              </span>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="content">
              <span className="main-content">
                <i className="fab fa-node fa-3x"></i>

                <h5 className="content-heading">Node JS</h5>
              </span>
              <span className="main-content">
                <i className="fab fa-php fa-3x"></i>

                <h5 className="content-heading">PHP</h5>
              </span>
              <span className="main-content">
                <i className="fab fa-java fa-3x"></i>

                <h5 className="content-heading">Java</h5>
              </span>
              <span className="main-content">
                <i className="fab fa-python fa-3x"></i>

                <h5 className="content-heading">Python</h5>
              </span>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="content">
              <span className="main-content">
                <i className="fab fa-wordpress fa-3x"></i>

                <h5 className="content-heading">Wordpress</h5>
              </span>
              <span className="main-content">
                <i className="fab fa-shopify fa-3x"></i>

                <h5 className="content-heading">Shopify</h5>
              </span>
              <span className="main-content">
                <i className="fab fa-drupal fa-3x"></i>

                <h5 className="content-heading">Drupal</h5>
              </span>
              <span className="main-content">
                <i className="fab fa-magento fa-3x"></i>

                <h5 className="content-heading">Magento</h5>
              </span>
              <span className="main-content">
                <i className="fab fa-umbraco fa-3x"></i>

                <h5 className="content-heading">Umbraco</h5>
              </span>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="content">
              <span className="main-content">
              <img src="https://img.icons8.com/metro/40/000000/mysql.png"/>

                <h5 className="content-heading">MySQL</h5>
              </span>
              <span className="main-content">
              <img src="https://img.icons8.com/color/40/000000/mongodb.png"/>

                <h5 className="content-heading">Mongo DB</h5>
              </span>
              <span className="main-content">
              <img src="https://img.icons8.com/color/40/000000/oracle-logo.png"/>

                <h5 className="content-heading">Oracle</h5>
              </span>
              <span className="main-content">
              <img src="https://img.icons8.com/color/40/000000/firebase.png"/>

                <h5 className="content-heading">Firebase</h5>
              </span>
              <span className="main-content">
              <img src="https://img.icons8.com/color/40/000000/postgreesql.png"/>

                <h5 className="content-heading">Postgre SQL</h5>
              </span>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="content">
              <span className="main-content">
                <i className="fab fa-aws fa-3x"></i>

                <h5 className="content-heading">AWS</h5>
              </span>
              <span className="main-content">
                <i className="fas fa-cloud fa-3x"></i>

                <h5 className="content-heading">Google Cloud</h5>
              </span>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </section>
  );
};

export default Tech;
