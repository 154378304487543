import React from 'react';
import './css/affirm.css';

const Affirmation = () => {
  return (
    <section className="hero is-large affirm-section">
      <div className="container">
        <div className="columns hero-text">
          <div className="column">
            <h4 className="section__title--intro1 mt-6 mb-5">
              Guaranteed customer satisfaction
            </h4>
            <h3 className="hero-title">We Promise, We Deliver</h3>
            <p className="mt-5 hero-para">
              Do you have an idea? Tell us about it. We promise to go the extra
              mile to give you the best as we do for every project that we take
              onboard. <br /> <br />
              With our 24/7 customer service, we are always available to help
              you with your needs.
            </p>

            <a href="/services" className="hero-btn btnn mt-6">
              Get in touch
            </a>
          </div>
          <div className="column mt-4">
            <img src="/images/deal.svg" alt="connect" className="hero-img" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Affirmation;
